import { Component } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { Events } from '@ionic/angular';
import { Router } from '@angular/router';
const { SplashScreen } = Plugins;
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Server } from '../app/provider/server';
import { AuthenticationService } from './services/Authentication.service';
import { AlertController, MenuController  } from '@ionic/angular';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent {
  jwt:any;
  auth:any;
  appPages = [
    {
      title: 'Categories',
      url: '/app/categories',
      icon: './assets/sample-icons/side-menu/categories.svg'
    },
    {
      title: 'Profile',
      url: '/app/user',
      icon: './assets/sample-icons/side-menu/profile.svg'
    },
    {
      title: 'Contact Card',
      url: '/contact-card',
      icon: './assets/sample-icons/side-menu/contact-card.svg'
    },
    {
      title: 'Notifications',
      url: '/app/notifications',
      icon: './assets/sample-icons/side-menu/notifications.svg'
    },
    {
      title: 'Log In',
      url: '/auth/login',
      icon: './assets/sample-icons/side-menu/login.svg'
    }
  ];
  accountPages = [
/*     {
      title: 'Log In',
      url: '/auth/login',
      icon: './assets/sample-icons/side-menu/login.svg'
    }, */
    {
      title: 'Sign Up',
      url: '/auth/signup',
      icon: './assets/sample-icons/side-menu/signup.svg'
    },
    {
      title: 'Tutorial',
      url: '/walkthrough',
      icon: './assets/sample-icons/side-menu/tutorial.svg'
    },
    {
      title: 'Getting Started',
      url: '/getting-started',
      icon: './assets/sample-icons/side-menu/getting-started.svg'
    },
    {
      title: '404 page',
      url: '/page-not-found',
      icon: './assets/sample-icons/side-menu/warning.svg'
    }
  ];

  textDir = 'ltr';

  constructor(public translate: TranslateService, private events: Events,  public menuCtrl: MenuController, private router: Router, private authenticationService: AuthenticationService, public alertController: AlertController) {
/*     this.authenticationService.authState.subscribe(state => {
      console.log(state)
      if(state){
        this.auth=1
      }
      else{
        this.auth=0
      }
      

    }); */
    let jwt = localStorage.getItem('id_token');
if(jwt){
  this.auth=1
}
else{
  this.auth=0
}
    events.subscribe('user:created', user => {
      console.log(user);
      if(user){
      this.auth=1
      }
      else{
        this.auth=0
      }
      //TO DO`enter code here`
    })
    this.initializeApp();
    this.setLanguage();
  }

  async presentAlertConfirm() {
    const alert = await this.alertController.create({
      header: '¡Aviso!',
      message: 'Deseas cerrar session',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          cssClass: 'secondary',
          handler: (blah) => {
            console.log('Confirm Cancel: blah');
          }
        }, {
          text: 'Si',
          handler: () => {
            this.authenticationService.logout()
            console.log('Confirm Okay');
          }
        }
      ]
    });
  
    await alert.present();
  }


  async initializeApp() {
    try {
     await SplashScreen.hide();
    } catch (err) {
     console.log('This is normal in a browser', err);
    }

  }
  salir(){
    localStorage.clear();
    this.router.navigate(['app/categories']);
    
  }
gotoHome(){
  this.menuCtrl.toggle();
  this.router.navigate(['app/categories']);
}
  setLanguage() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use('en');

    // this is to determine the text direction depending on the selected language
    // for the purpose of this example we determine that only arabic and hebrew are RTL.
    // this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
    //   this.textDir = (event.lang === 'ar' || event.lang === 'iw') ? 'rtl' : 'ltr';
    // });
  }

}
