import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { FileUploadModule } from "ng2-file-upload";   //Should import HERE
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SettingsComponent } from './travel/details/popover/popover.page';
import { Ordenar } from './travel/listing/popover/popover.page';
import { ComponentsModule } from './components/components.module';
//import { Keyboard } from '@ionic-native/keyboard/ngx';
import { FirebaseUpdateUserModal2 } from './update/firebase-update-user2.modal'
import { ServiceWorkerModule } from '@angular/service-worker';
import { SelectUserImageModal } from './firebase/user/select-image/select-user-image.modal';
import { environment } from '../environments/environment';
import { ReactiveFormsModule } from '@angular/forms';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard } from '../app/services/auth-guard.service';
import { AuthenticationService } from './services/Authentication.service';
import { Server } from '../app/provider/server';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
 
@NgModule({
  declarations: [AppComponent, SettingsComponent, Ordenar,  FirebaseUpdateUserModal2, SelectUserImageModal],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    ReactiveFormsModule,
    AppRoutingModule,
    FileUploadModule,
    ComponentsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, Server, AuthGuard,
    AuthenticationService// Keyboard
  ],
  entryComponents: [
    SettingsComponent,
    FirebaseUpdateUserModal2,
    SelectUserImageModal,
    Ordenar
  ],

  bootstrap: [AppComponent]
})
export class AppModule {}
