import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, NavParams } from '@ionic/angular';
import { Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import {Server} from '../provider/server';
import * as dayjs from 'dayjs';
import { FileUploader    } from 'ng2-file-upload';

import { CheckboxCheckedValidator } from '../validators/checkbox-checked.validator';

//import { FirebaseService } from '../firebase/firebase-integration.service';
import { FirebaseUserModel } from '../firebase/user/firebase-user.model';
import { SelectUserImageModal } from '../firebase/user/select-image/select-user-image.modal';
const  URL = 'https://armangalstyan.com/images/ngupimage.php';
@Component({
  selector: 'app-firebase-update-user',
  templateUrl: './firebase-update-user2.modal.html',
  styleUrls: [
    './styles/firebase-update-user.modal.scss',
    './styles/firebase-update-user.shell.scss'
  ],
})
export class FirebaseUpdateUserModal2 {
  uploader:FileUploader;
  carpeta:any="";
  indice=0;
  // "user" is passed in firebase-details.page
  //@Input() user: FirebaseUserModel;
user:any;
  updateUserForm: FormGroup;
  selectedAvatar: any;
    selectedAvatar2: any;
    filename:any;
  checked1:any;
  checked2:any;
  checked3:any;
  //image:any;

  //skills = [];
  public skills = [
    { val: 'Inventor', isChecked: false },
    { val: 'Inversor', isChecked: false },
    { val: 'Empresario', isChecked: false }
  ];
  constructor(
    private modalController: ModalController,
    public alertCtrl: AlertController,
    private navParams: NavParams,
    private server:Server,  

  //  public firebaseService: FirebaseService,
    public router: Router
  ) { 
    this.uploader = new FileUploader({
      url: URL, // url del php que trata el fichero subido
      method: 'POST',
      removeAfterUpload: true, // lo quita de la lista una vez su subida fue correcta
      queueLimit: 5, // limite de archivos que se pueden añadir a la lista, si el numero de archivos seleccionados es superior se cogen los x primeros.
      
    });
    this.uploader.onBuildItemForm = (fileItem: any, form: any) => { 
      form.append('id' , this.indice); 
      form.append('fotonum' , 'n1'); 
      form.append('carpeta' , this.carpeta); 
      this.indice = this.indice + 1;
    };
/*     this.uploader.onAfterAddingFile = function(fileItem) {
      if (fileItem.file.size > 2048000) {
        let prompt = this.alertCtrl.create({
          title: 'Error de archivo',
          message: "El archivo que está intentando subir sobrepasa el limite de tamaño de 2mb, seleccione otro archivo.",
          buttons: [
            {
              text: 'Aceptar',
              handler: data => {

                fileItem.cancel();
                fileItem.remove(); 
               
              }
            }
          ]
        });
        prompt.present();
      }
      this.alllert()
  console.info('onAfterAddingFile', fileItem);
  
}; */


this.uploader.onAfterAddingFile = (fileItem) => {
  console.log("JUST ADDED A FILE: " + fileItem._file.name);
  this.filename=fileItem._file.name
  this.readLocalFile(String(this.uploader.queue.length - 1), fileItem);
  
  this.updateImagesInQueue();
  this.uploader.uploadAll()
}
// Función que salta cuando se han añadido todos los archivos seleccionados.
this.uploader.onAfterAddingAll = function(addedFileItems) {
  console.info('onAfterAddingAll');

  // console.log(this.uploader.queue[0])
};
// Función que se ejecuta antes de subir un archivo. Aquí añadimos datos adicionales necesarios para renombrar el
// archivo como nosotros queremos.
this.uploader.onBeforeUploadItem = function(item) {
  console.info('onBeforeUploadItem');
};
// Barra de progreso que indica la subida.
this.uploader.onProgressItem = function(fileItem, progress) {
  console.info('onProgressItem');
};
// Función que se ejecuta cuando la barra de progreso llega a su fin.
this.uploader.onProgressAll = function(progress) {
  console.info('onProgressAll');
};
// Función que salta si la subida del archivo se produjo con éxito en nuestro caso guardamos los índices de las
// imágenes para almacenarlos posteriormente en la bdd.
this.uploader.onSuccessItem = function(fileItem, response, status, headers) {
  console.info('onSuccessItem', fileItem, response, status, headers);

};
// Función que salta si se produce un error en la subida.
this.uploader.onErrorItem = function(fileItem, response, status, headers) {
  console.info('onErrorItem', fileItem, response, status, headers);
};
// Función que se ejecuta si se cancela la subida.
this.uploader.onCancelItem = function(fileItem, response, status, headers) {
  console.info('onCancelItem', fileItem, response, status, headers);
};

this.uploader.onCompleteItem = function(fileItem, response, status, headers) {
  console.info('onCompleteItem');
};
this.uploader.onCompleteAll = () =>  {
  console.info('onCompleteAll');
  this.selectedAvatar="https://armangalstyan.com/images/images/" + this.filename
};



   }
   updateImagesInQueue() {
    let i = 0;
    for (let file of this.uploader.queue) {
      console.log(file)
        this.readLocalFile(String(i), file);
        i++;
    }
}

readLocalFile(id, ff) {
var reader = new FileReader();
reader.onload = readSuccess;


function readSuccess(evt) {
/*   this.image = new Image();
  this.image.src = evt.target.result; */
   this.selectedAvatar2 = evt.target.result;
   console.log(this.selectedAvatar2)
   return evt;
};
reader.readAsDataURL(ff._file);
/* var image = new Image();
image.src = this.selectedAvatar2; */

console.log(this.selectedAvatar)
}



 openFileDialog1 = function() {
   
  if (this.carpeta.length < 5) {
      alert("El nombre de carpeta no ha sido especificado o no se trata de un nombre valido.");
  } else {
      document.getElementById('seleccion').click();
  }
};

  ngOnInit() {
    this.user = this.navParams.get('user')
    console.log(this.user)
    this.selectedAvatar ="https://armangalstyan.com/images/images/" + this.user.user_image;

    this.updateUserForm = new FormGroup({
      name: new FormControl('', Validators.required),
      apellido: new FormControl('', Validators.required),
      email: new FormControl('', Validators.compose([
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')
      ])),
      phone: new FormControl('', Validators.required),
    carpeta:new FormControl(''),
      skills: new FormControl(''),
      inventor: new FormControl(''),
      inversor: new FormControl(''),
      empresario: new FormControl(''),
      desc:new FormControl('')

    });

/*     this.firebaseService.getSkills().subscribe(skills => {
      this.skills = skills;
      // create skill checkboxes
      this.skills.map((skill) => {
        let userSkillsIds = [];
        if (this.user.skills) {
          userSkillsIds = this.user.skills.map(function(skillId) {
            return skillId['id'];
          });
        }
        // set the control value to 'true' if the user already has this skill
        const control = new FormControl(userSkillsIds.includes(skill.id));
        (this.updateUserForm.controls.skills as FormArray).push(control);
      });
    }); */
  }

  onTermsChecked1($event)
{
  console.log($event)
  console.log(this.updateUserForm)
  this.checked1= $event.detail.checked
    if ( this.checked1)
    {
        this.updateUserForm.patchValue({ inventor: 1 });
        this.updateUserForm.patchValue({ checked: this.checked1});
        console.log(this.updateUserForm.value)
        
    }
    else if( !this.checked1 && !this.checked2 && !this.checked3) {
      this.updateUserForm.patchValue({ inventor: null });
      this.updateUserForm.patchValue({ checked: null});
      console.log(this.updateUserForm.value)
    }
}
onTermsChecked2($event)
{
  console.log($event)
  console.log(this.updateUserForm)
  this.checked2=$event.detail.checked
    if (  this.checked2)
    {
        this.updateUserForm.patchValue({ inversor: 1 });
        this.updateUserForm.patchValue({ checked:  this.checked2});
        console.log(this.updateUserForm.value)
        
    }
    else if( !this.checked1  && !this.checked2 && !this.checked3 ) {
      this.updateUserForm.patchValue({ inversor: null });
      this.updateUserForm.patchValue({ checked: null});
      console.log(this.updateUserForm.value)
    }
}
onTermsChecked3($event)
{
  console.log($event)
  console.log(this.updateUserForm)
  this.checked3=$event.detail.checked
    if ( this.checked3)
    {
        this.updateUserForm.patchValue({ empresario: 1 });
        this.updateUserForm.patchValue({ checked: this.checked3});
        console.log(this.updateUserForm.value)
        
    }
    else if( !this.checked1 && !this.checked2 && !this.checked3) {
      this.updateUserForm.patchValue({ empresario: null });
      this.updateUserForm.patchValue({ checked: null});
      console.log(this.updateUserForm.value)
    }
}



  get skillsFormArray() { return <FormArray>this.updateUserForm.get('skills'); }

  changeLangValue(value): string {
    switch (true) {
      case (value <= 3 ):
        return 'Novice';
      case (value > 3 && value <= 6 ):
        return 'Competent';
      case (value > 6 ):
        return 'Expert';
    }
  }

  dismissModal() {
   this.modalController.dismiss();
  }

  async deleteUser() {
    const alert = await this.alertCtrl.create({
      header: 'Confirm',
      message: 'Do you want to delete ' + this.user.name + '?',
      buttons: [
        {
          text: 'No',
          role: 'cancel',
          handler: () => {}
        },
        {
          text: 'Yes',
          handler: () => {
/*             this.firebaseService.deleteUser(this.user.id)
            .then(
              () => {
                this.dismissModal();
                this.router.navigate(['firebase/listing']);
              },
              err => console.log(err)
            ); */
          }
        }
      ]
    });
    await alert.present();
  }

  updateUser() {
/*     this.server.modUser(this.updateUserForm).subscribe(data => {
   
console.log(data)
    }) */
  
console.log("exav")
console.log(this.updateUserForm.value)
if(this.filename==undefined){
  this.filename=this.user.user_image
}
//this.uploader.uploadAll()
this.server.modUser(this.updateUserForm.value,  this.user.id, this.filename).subscribe(data => {
   
  console.log(data)
  this.dismissModal()
      }) 
  }
async alllert(){
  let prompt = await  this.alertCtrl.create({
    header: 'Error de archivo',
    message: "El archivo que está intentando subir sobrepasa el limite de tamaño de 2mb, seleccione otro archivo.",
    buttons: [
      {
        text: 'Aceptar',
        handler: data => {
this.uploader.uploadAll()
        /*   fileItem.cancel();
          fileItem.remove(); */
         
        }
      }
    ]
  });
  await prompt.present();

}
  async changeUserImage() {
    document.getElementById('seleccion').click();

  }
}
