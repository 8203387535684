import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Server} from '../provider/server';
import { ToastController, Platform, LoadingController, AlertController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Events } from '@ionic/angular';
 
@Injectable()
export class AuthenticationService {
 
  authState = new BehaviorSubject(false);
 
  constructor(
    private router: Router,
    private server:Server,
    private platform: Platform,
    public toastController: ToastController,
    public loadingController: LoadingController,
    public alertController:AlertController,
    private events: Events
  ) {
    this.platform.ready().then(() => {
      this.ifLoggedIn();
    });
  }
 
  ifLoggedIn() {
    let jwt = localStorage.getItem('id_token');
    console.log(this.authState.value);

      if (jwt) {
        this.authState.next(true);
      }
   
  }
 
 
  login(value) {
    this.server.getUser(value.email, value.password).subscribe(token => {
    
  console.log(token);
       // If the user credentials are valid, the current user is redirected to the home page.
       if (token.token && token != 'undefined' && token != 'No user found') {
   
         localStorage.setItem('id_token', token.token);
         localStorage.setItem('email', value.email);
         this.events.publish('user:created', 1);
         this.authState.next(true);
         console.log(this.authState)
        // this.events.publish('user:created', 1);
        this.router.navigate(['app/categories']);
        
         
   
       } else {
         console.log(value.email);
         console.log(value.password);
         this.alertConnexionError();
       }
     });




  }
  async alertConnexionError() {
    const alert = await this.alertController.create({
      header: '¡Aviso!',
      subHeader: 'Error de autentificación',
      message: 'Por favor compruebe email o contraseña',
      buttons: ['OK']
    });

    await alert.present();
  }
  logout() {
   // localStorage.removeItem('id_token');
   localStorage.clear()
   this.events.publish('user:created', 0);
   this.authState.next(false);
      this.router.navigate(['app/categories']);
     
    
  }
 
  isAuthenticated() {
    return this.authState.value;
  }
 
 
 
}