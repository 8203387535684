import {Injectable} from '@angular/core';
//import {AuthHttp} from 'angular2-jwt';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
//import {Response, Http, Headers, RequestOptions} from '@angular/http';
import {Observable, of}  from 'rxjs';
//import 'rxjs/Rx';
//import 'rxjs/add/operator/map';
import { map, catchError } from 'rxjs/operators';
//import { GroupByPipe } from '../pages/firebase-integration/firebase-feed/group-by.pipe';
@Injectable()
export class Server {
 private baseUrl = 'https://armangalstyan.com/cdi/public/';
 constructor(public http:HttpClient
    /* public authHttp:AuthHttp */) {
    }

    /** 
     * Authentication request to the server.
     *
     * @param user_login
     * @param user_password
     * @returns {Observable<Response>}
     

     */




     getFases():Observable<any> {
      const httpOptions = {
        headers: new HttpHeaders({
            'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
   
  // let options = new RequestOptions({headers: authHeader});
   return this.http.get('https://armangalstyan.com/cdi/public/api/getFases', httpOptions)
     .pipe(map((response: any) => response),
     catchError(<T>(error: any, result?: T) => {
      console.log(error);
      return of(result as T);
    })
     )
 }
 getParrentCategories():Observable<any> {
   
  const httpOptions = {
    headers: new HttpHeaders({
         'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  
  
  const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
 // let options = new RequestOptions({headers: authHeader});
  return this.http.get('https://armangalstyan.com/cdi/public/api/getParrentCategories', httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}
 geCategories():Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

 // let options = new RequestOptions({headers: authHeader});
  return this.http.get('https://armangalstyan.com/cdi/public/api/getCategories', httpOptions)
    .pipe(map((response: any) => response['Ctaegories']),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}
getPatentes(id):Observable<any> {
/*   let jwt ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1NzYwMTQ0OTMsImV4cCI6MTU4Mzc5MDQ5MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjIwIn19fQ.YMm1xqJXZu8mEdVKn6xkz-NbIDzQLd_W8egwpemp0kU'  //localStorage.getItem('id_token');
  
  let authHeader = new Headers();
  if (jwt) {
   var jwt2=jwt
  } */

  const httpOptions = {
    headers: new HttpHeaders({
        'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  const body = 'lastcreated=' + id;
 // let options = new RequestOptions({headers: authHeader});
  return this.http.post('https://armangalstyan.com/cdi/public/api/getPatentes',  body, httpOptions)
    .pipe(map((response: any) => response['Patentes']),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}


getPatentesByPrice(id):Observable<any> {
  console.log(id)
  const httpOptions = {
    headers: new HttpHeaders({
        'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  const body = 'lastcreated=' + id;

 // let options = new RequestOptions({headers: authHeader});
  return this.http.post('https://armangalstyan.com/cdi/public/api/getPatentesByPrice', body, httpOptions)
    .pipe(map((response: any) => response['Patentes']),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

getPatentesByFecha():Observable<any> {
  let jwt ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1NzYwMTQ0OTMsImV4cCI6MTU4Mzc5MDQ5MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjIwIn19fQ.YMm1xqJXZu8mEdVKn6xkz-NbIDzQLd_W8egwpemp0kU'  //localStorage.getItem('id_token');
  
  let authHeader = new Headers();
  if (jwt) {
   var jwt2=jwt
  }
 // let options = new RequestOptions({headers: authHeader});
  return this.http.get('https://armangalstyan.com/cdi/public/api/getPatentesByFecha', {
   headers: {'Authorization':jwt2} })
    .pipe(map((response: any) => response['Patentes']),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

getMisPatentes():Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
 // let options = new RequestOptions({headers: authHeader});
  return this.http.get('https://armangalstyan.com/cdi/public/api/getMisPatentes', httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

getMisPatentesCount():Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
 // let options = new RequestOptions({headers: authHeader});
  return this.http.get('https://armangalstyan.com/cdi/public/api/getMisPatentesCount', httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

getUser(user_login, user_password):any {
  let body = "user_login=" + user_login + "&user_password=" + user_password;
//console.log(body)
  const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });



  return this.http.post(`${this.baseUrl}api/user/login`, body, { headers })
  .pipe(map((response: any) => response),
  catchError(<T>(error: any, result?: T) => {
   console.log(error);
   return of(result as T);
 })
  )
}
getUserDetails():any {

  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  

/* if (jwt) {
  console.log(jwt)
  var jwt2=jwt
} */

 //const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
 

  return this.http.get(`${this.baseUrl}api/getUser`, httpOptions)
    /* .pipe(map((response: any) => response )
    ) */
    .pipe(map((response: any) => response),
  catchError(<T>(error: any, result?: T) => {
   console.log(error);
   return of(result as T);
 })
    )
}
addFavorites(id):Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  const body = 'id=' + id;

 // let options = new RequestOptions({headers: authHeader});
  return this.http.post('https://armangalstyan.com/cdi/public/api/insertFavoritos', body, httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}
getFavorites():Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };


 // let options = new RequestOptions({headers: authHeader});
  return this.http.get('https://armangalstyan.com/cdi/public/api/getFavoritos', httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

checkIfLiked(id):Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  const body = 'id=' + id;

 // let options = new RequestOptions({headers: authHeader});
  return this.http.post('https://armangalstyan.com/cdi/public/api/ifliked', body, httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

remFavorites(id):Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  const body = 'id=' + id;

 // let options = new RequestOptions({headers: authHeader});
  return this.http.post('https://armangalstyan.com/cdi/public/api/delFavoritos', body, httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

delPatente(id):Observable<any> {
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  const body = 'id=' + id;

 // let options = new RequestOptions({headers: authHeader});
  return this.http.post('https://armangalstyan.com/cdi/public/api/delPatente', body, httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}



getPatentesById(id): Observable<boolean> {
  let jwt2 ='eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC95b3VyLWRvbWFpbi5jb20iLCJpYXQiOjE1NzYwMTQ0OTMsImV4cCI6MTU4Mzc5MDQ5MywiY29udGV4dCI6eyJ1c2VyIjp7InVzZXJfbG9naW4iOiJhcm1hbmcyMDZAZ21haWwuY29tIiwidXNlcl9pZCI6IjIwIn19fQ.YMm1xqJXZu8mEdVKn6xkz-NbIDzQLd_W8egwpemp0kU'  //localStorage.getItem('id_token');
  console.log(typeof (id))
  const body = 'id=' + id;
 
  const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });

  return this.http.post('https://armangalstyan.com/cdi/public/api/PatentesById', body, { headers })
  .pipe(map((response: any) => response['Patentes']),
  catchError(<T>(error: any, result?: T) => {
   console.log(error);
   return of(result as T);
 })
  )
}

addPatentes(form, form2, form3, form4, form5, form6, form7, form8, images, portada, cat_to, video){
  console.log(images)
  console.log(portada)
  console.log(images[2])

  
  if(!images[0]){
    var image1=undefined
    console.log(images[1])
  }else{
    var image1=images[0].filename
  }

  if(!images[1]){
    var image2=undefined
    console.log(images[2])
  }else{
    var image2=images[1].filename
  }

  if(!images[2]){
    var image3=undefined
    console.log(images[3])
  }else{
    var image3=images[2].filename
  }

  if(!images[3]){
    var image4=undefined
    console.log(images[4])
  }else{
    var image4=images[3].filename
  }



if(!images[4]){
  var image5=undefined
  console.log(images[5])
}else{
  var image5=images[4].filename
}

if(!video[0]){
  var video=undefined
 // console.log(video[0])
}else{
  var video=video[0].filename
}

  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  const body = 'inventor=' + form.inventor + '&name=' + form.titulo + '&categoria=' + form.categoria + '&price1=' + form2.precio + '&price2=' + form2.precioalq + '&price3=' + form2.precioinv + '&descrip=' + form3.desc + '&prob_sol=' + form4.probsol + '&plan_inv=' + form5.planinv + '&titular=' + form7.titular + 
  '&num_pat=' + form7.numero_patent + '&fecha=' + form7.fecha_reg + '&percent_rent=' + form2.percent + '&percent_inv=' + form2.percentinv
  + '&img1=' + image1 +   '&img2=' + image2 + '&img3=' + image3 + '&img4=' + image4 + '&img5=' + image5 + '&portada=' + portada + '&fases=' + cat_to + '&video=' + video;
  console.log(body)
 // let options = new RequestOptions({headers: authHeader});
  return this.http.post('https://armangalstyan.com/cdi/public/api/insertPatentes', body, httpOptions)
    .pipe(map((response: any) => response),
    catchError(<T>(error: any, result?: T) => {
     console.log(error);
     return of(result as T);
   })
    )
}

regUser(value):Observable<any> {
  let body = "nombre=" + value.nombre + "&email=" + value.email + "&password=" + value.matching_passwords.password + "&apellido=" + value.apellido + "&telefono=" + value.telefono + "&inventor=" + value.inventor + "&inversor=" + value.inversor  + "&empresario=" + value.empresario;

  const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });


 

  return this.http.post(`${this.baseUrl}api/user/register`, body, { headers })
  .pipe(map((response: any) => response),
  catchError(<T>(error: any, result?: T) => {
    console.log(error);
    return of(result as T);
  })
  )
}

modUser(value, id, filename):Observable<any> {
  console.log(value)
  const httpOptions = {
    headers: new HttpHeaders({
        'Authorization':  localStorage.getItem('id_token'), 'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  let body = "nombre=" + value.name + "&apellido=" + value.apellido +  "&telefono=" + value.phone + "&inventor=" + value.inventor + "&inversor=" + value.inversor  + "&empresario=" + value.empresario + "&photo=" + value.desc + "&user_image=" + filename  + "&id=" + id;

  const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });


 

  return this.http.post(`${this.baseUrl}api/updateUsers`, body, httpOptions)
  .pipe(map((response: any) => response),
  catchError(<T>(error: any, result?: T) => {
    console.log(error);
    return of(result as T);
  })
  )
}



filter(fases, categorias, id):Observable<any> {
  console.log(fases)
  console.log(categorias)
/*   if(!fases[1]){
    var fases1=undefined
    console.log(fases1[1])
  }else{
    var fases1=fases[1]
  } */
  
  const httpOptions = {
    headers: new HttpHeaders({
         'ACCEPT':'application/x-www-form-urlencoded',  'Content-Type': 'application/x-www-form-urlencoded'
    })
  };
  let body = "fase1=" + fases[0] + "&fase2=" + fases[1] +  "&fase3=" + fases[2] + "&fase4=" + fases[3] + "&fase5=" + fases[4]  + 
  "&fase6=" + fases[5] + "&fase7=" + fases[6] + "&fase8=" + fases[7] + "&categorias=" + categorias + 'id=' + id ;  /* + "&categorias=" + categorias */

  const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });


 

  return this.http.post(`${this.baseUrl}api/getPatentesFilter`, body, httpOptions)
  .pipe(map((response: any) => response),
  catchError(<T>(error: any, result?: T) => {
    console.log(error);
    return of(result as T);
  })
  )
}


 /**
 *
 *
* @param res
* @returns {any|{}}
*/

/*  private extractData(res:Response) {
  let body = res.json(); //res.json()
  return body || {};
}
private extractData2(res:Response) {
  let body = res; //res.json()
  return body || {};
} */
/**
 * Handling errors.
 *
 * @param error
 * @returns {ErrorObservable}
 */
/* private handleError(error:any) {
  let errMsg = (error.message) ? error.message :
    error.status ? ${error.status} - ${error.statusText} : 'Server error';
  console.log(errMsg); // log to console instead
  return Observable.throw(errMsg);
} */
}